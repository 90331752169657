import styled, { css } from 'styled-components';

import { getVariant, query } from '@shared/style-helpers';

const descriptionVariants = {
  default: css`
    color: ${({ theme }) => theme.colors.darkBlue_60};
  `,
  dark: css`
    color: ${({ theme }) => theme.colors.white};
  `,
  blue: css`
    color: ${({ theme }) => theme.colors.darkBlue_60};
  `,
};

export default styled('p')`
  && {
    text-align: left;
    font-size: 12px;

    @media ${query.minDesktop} {
      text-align: center;
      font-size: 14px;
    }

    ${getVariant(descriptionVariants)}
  }
`;

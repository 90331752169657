import React from 'react';
import styled from 'styled-components';

import { Icon, Text } from '@components';

const StarWrapper = styled('div')`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CardStar = ({ title }) => (
  <StarWrapper>
    <Icon.Star color="warning" />
    {title && (
      <Text.Body4Strong color="warning" ml={8}>
        {title}
      </Text.Body4Strong>
    )}
  </StarWrapper>
);

export default CardStar;

import styled, { css } from 'styled-components';

import { getVariant, query } from '@shared/style-helpers';

const variants = {
  default: css`
    color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.darkBlue)};
  `,
  dark: css`
    color: ${({ theme }) => theme.colors.danger};
  `,
  blue: css`
    color: ${({ theme }) => theme.colors.primary};
  `,
};

export default styled('p')`
  && {
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    text-align: right;
    margin-left: 4px;
    margin-right: 4px;

    @media ${query.minDesktop} {
      font-size: 22px;
      text-align: center;
    }

    ${getVariant(variants)};
  }
`;

import Duration from './text-duration';
import RegularDuration from './text-duration-regular';
import Price from './text-price';
import PriceDecorator from './text-price-decorators';
import RegularPrice from './text-regular-price';
import Description from './text-description';
import Coupon from './text-coupon';

export const Text = {
  Duration,
  RegularDuration,
  PriceDecorator,
  Price,
  RegularPrice,
  Description,
  Coupon,
};

import React from 'react';

import { Show } from '@core';

import { CardContainer, CardContentContainer, CardHighlight, CardSticker } from './components';
import { LayoutDesktop, LayoutMobile } from './layouts';

const PlanCard = ({
  clickHandler,
  isActive,
  isHighlighted,
  isLoading,
  variant,
  price,
  currency,
  pricePeriod,
  regularPricePerMonth,
  description,
  billingDescription,
  starLabel,
  stickerLabel,
  highlightLabel,
  title,
  regularTitle,
  couponText,
  planId,
}) => (
  <CardContainer variant={variant} isActive={isActive} onClick={clickHandler}>
    {stickerLabel && (
      <CardSticker variant={variant} isHighlighted={isHighlighted}>
        {stickerLabel}
      </CardSticker>
    )}

    <CardContentContainer variant={variant} isLoading={isLoading} isActive={isActive}>
      <Show.TabletLarge>
        <LayoutMobile
          variant={variant}
          isActive={isActive}
          isHighlighted={isHighlighted}
          regularTitle={regularTitle}
          price={price}
          currency={currency?.toUpperCase()}
          pricePeriod={pricePeriod}
          regularPricePerMonth={regularPricePerMonth}
          description={description}
          billingDescription={billingDescription}
          title={title}
          couponText={couponText}
        />
      </Show.TabletLarge>
      <Show.DesktopAndUp>
        <LayoutDesktop
          planId={planId}
          variant={variant}
          isActive={isActive}
          isHighlighted={isHighlighted}
          starLabel={starLabel}
          regularTitle={regularTitle}
          price={price}
          currency={currency?.toUpperCase()}
          pricePeriod={pricePeriod}
          regularPricePerMonth={regularPricePerMonth}
          description={description}
          billingDescription={billingDescription}
          title={title}
          couponText={couponText}
        />
      </Show.DesktopAndUp>
    </CardContentContainer>

    {highlightLabel && isHighlighted && <CardHighlight variant={variant} label={highlightLabel} />}
  </CardContainer>
);

export default PlanCard;

import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@components/typography';
import { getVariant, query } from '@shared/style-helpers';

const variants = {
  default: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.success};
  `,
  dark: css`
    color: ${({ theme }) => theme.colors.darkBlue};
    background-color: ${({ theme }) => theme.colors.white};
  `,
  blue: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary_120};
  `,
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding: 12px;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;

  @media ${query.maxTablet} {
    padding: 12px;
    height: 40px;
  }

  ${getVariant(variants)}
`;

const Spacer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  transform: translateY(-100%);
  background-color: inherit;
`;

const HighlightText = styled('p')`
  && {
    color: inherit;
    font-size: 12px;
    font-weight: 600;

    @media ${query.minDesktop} {
      font-size: 14px;
    }
  }
`;

const CardHighlight = ({ label, variant }) => (
  <Container variant={variant}>
    <Spacer />
    <Text.WithIcon textComponent={HighlightText}>{label}</Text.WithIcon>
  </Container>
);

export default CardHighlight;

import styled from 'styled-components';

import { query } from '@shared/style-helpers';

export default styled('div')`
  width: 100%;
  margin-top: 16px;
  min-width: 270px;
  border-radius: 8px;
  box-shadow: ${({ isActive }) => isActive && '0 0 14px 0 rgba(208, 208, 208, 0.5)'};

  font-family: ${({ theme }) => theme?.fonts?.default};

  position: relative;
  display: flex;
  flex-direction: column;

  @media ${query.minDesktop} {
    max-width: 360px;
  }
`;

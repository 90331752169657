import React from 'react';
import styled, { css } from 'styled-components';

import { getVariant, query } from '@shared/style-helpers';
import { Icon } from '@components';

const variants = {
  default: css`
    color: ${({ isActive, theme }) => (isActive ? theme.colors.white : theme.colors.primary_20)};
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.primary : theme.colors.primary_20};
  `,
  dark: css`
    color: ${({ theme }) => theme.colors.danger};
    background-color: ${({ theme }) => theme.colors.white};
  `,
  blue: css`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  `,
};

const BadgeContainer = styled('div')`
  border-radius: 4px;

  width: 24px;
  height: 24px;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 23px;

  @media ${query.maxTablet} {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 16px;
  }

  ${getVariant(variants)}
`;

const CardBadge = ({ variant, isActive }) => (
  <BadgeContainer variant={variant} isActive={isActive}>
    <Icon.Check size={20} color="inherit" />
  </BadgeContainer>
);

export default CardBadge;

import React from 'react';
import styled, { css } from 'styled-components';

import { Loader } from '@components';

import { getVariant, query } from '@shared/style-helpers';

const variants = {
  default: css`
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.primary : theme.colors.primary_20};

    &:hover {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  `,
  dark: css`
    border-color: ${({ theme }) => theme.colors.white};
    background-image: linear-gradient(to bottom, #3b3b4d, #000000);
  `,
  blue: css`
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: #f6f9ff;
  `,
};

const ContentContainer = styled('div')`
  z-index: 1;
  border: 4px solid;
  border-radius: 8px;
  box-shadow: ${({ isActive }) => isActive && '0 0 14px 0 rgba(134, 134, 134, 0.5)'};
  cursor: pointer;

  ${getVariant(variants)}
`;

const LoaderWrapper = styled('div')`
  display: flex;
  height: 180px;
  align-items: center;
  justify-content: center;

  @media ${query.minDesktop} {
    height: 340px;
  }
`;

const CardContent = ({ variant, isLoading, isActive, children }) => (
  <ContentContainer variant={variant} isActive={isActive}>
    {isLoading ? (
      <LoaderWrapper>
        <Loader isLoading />
      </LoaderWrapper>
    ) : (
      children
    )}
  </ContentContainer>
);

export default CardContent;

import React from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@core';

import { CardBadge, CardStar, Text } from './components';

const ContentContainer = styled('div')`
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 48px 20px 34px 20px;

  &:focus,
  &:hover,
  &:active {
    outline: unset;
  }
`;

const PriceBlock = styled('div')`
  margin-bottom: 20px;
`;

const BadgeWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
`;

const LayoutDesktop = ({
  variant,
  isActive,
  isHighlighted,
  starLabel,
  price,
  pricePeriod,
  regularPricePerMonth,
  description,
  billingDescription,
  currency,
  title,
  regularTitle,
  couponText,
  planId,
}) => (
  <ContentContainer data-testid={`plan-card-${planId}`}>
    {isHighlighted && starLabel && (
      <Box position="absolute" top={-50}>
        <CardStar title={starLabel} />
      </Box>
    )}
    <BadgeWrapper>
      <CardBadge variant={variant} isActive={isActive} />
    </BadgeWrapper>

    <PriceBlock>
      <Box minHeight={50}>
        <Text.Duration variant={variant} data-testid={`plan-duration-${planId}`}>
          {title}
        </Text.Duration>
        {regularTitle && (
          <Text.RegularDuration variant={variant}>
            {'\u00A0'}
            {regularTitle}
            {'\u00A0'}
          </Text.RegularDuration>
        )}
      </Box>
      {couponText && <Text.Coupon dataTestId={`text-coupon-${planId}`}>{couponText}</Text.Coupon>}
      <Grid.Row alignItems="center" justifyContent="center" flexWrap="wrap" mt={32}>
        <Text.PriceDecorator isActive={isActive} variant={variant}>
          {currency}
        </Text.PriceDecorator>
        <Text.Price isActive={isActive} variant={variant}>
          {price}
        </Text.Price>
        <Text.PriceDecorator isActive={isActive} variant={variant}>
          {pricePeriod}
        </Text.PriceDecorator>
      </Grid.Row>
      {regularPricePerMonth && isHighlighted && (
        <Text.RegularPrice variant={variant}>{regularPricePerMonth}</Text.RegularPrice>
      )}
    </PriceBlock>

    <div>
      <Text.Description variant={variant} data-testid={`billing-description-${planId}`}>
        {billingDescription}
      </Text.Description>
      <Text.Description variant={variant}>{description}</Text.Description>
    </div>
  </ContentContainer>
);

export default LayoutDesktop;

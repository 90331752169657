import React from 'react';
import styled, { css } from 'styled-components';

import { getVariant, query } from '@shared/style-helpers';
import { Text } from '@components';

const variants = {
  default: css`
    color: ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.white : theme.colors.darkBlue};
    background-color: ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.danger : theme.colors.primary_20};
  `,
  dark: css`
    background-color: ${({ theme }) => theme.colors.danger};
  `,
  blue: css`
    background-color: ${({ theme }) => theme.colors.danger};
  `,
};

const StickerContainer = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  padding: 4px 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.danger};

  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
  font-weight: 500;

  @media ${query.minDesktop} {
    padding: 0 28px;
  }

  ${getVariant(variants)}
`;

const CardSticker = ({ isHighlighted, variant, children }) => (
  <StickerContainer isHighlighted={isHighlighted} variant={variant}>
    <Text.Body1
      $fontSize={{ desktop: 16, mobile: 14 }}
      $lineHeight={{ desktop: '32px', mobile: '18px' }}
      color="inherit"
      fontWeight={{ _: 600, lg: 700 }}
      textAlign="center"
    >
      {children}
    </Text.Body1>
  </StickerContainer>
);

export default CardSticker;

import styled, { css } from 'styled-components';

import { getVariant, query } from '@shared/style-helpers';

const variants = {
  default: css`
    color: ${({ theme }) => theme.colors.darkBlue};
  `,
  dark: css`
    color: ${({ theme }) => theme.colors.white};
  `,
  blue: css`
    color: ${({ theme }) => theme.colors.darkBlue};
  `,
};

export default styled('p')`
  && {
    font-size: 14px;
    text-align: left;
    font-weight: bold;
    text-decoration: line-through;

    @media ${query.minDesktop} {
      text-align: center;
      font-size: 18px;
      margin-top: 16px;
    }

    ${getVariant(variants)}
  }
`;

import React from 'react';

import { Text } from '@components';

const TextCoupon = ({ children, dataTestId }) => (
  <Text.Body2
    data-testid={dataTestId}
    $fontSize={{ mobile: 12, desktop: 14 }}
    $lineHeight={'14px'}
    textAlign={{ _: 'left', lg: 'center' }}
    mt={{ _: 8, md: 12 }}
    mb={{ _: 12, md: 'unset' }}
  >
    {children}
  </Text.Body2>
);

export default TextCoupon;

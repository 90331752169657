import React from 'react';

import { Box, Grid } from '@core';

import { CardBadge, Text } from './components';

const LayoutMobile = ({
  variant,
  isActive,
  isHighlighted,
  price,
  currency,
  pricePeriod,
  regularPricePerMonth,
  description,
  billingDescription,
  title,
  regularTitle,
  couponText,
}) => (
  <Box
    display="flex"
    minHeight={160}
    flexDirection="column"
    alignItems="stretch"
    px={15}
    py={20}
    justifyContent="space-between"
  >
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <Box display="flex">
        <Box mr={{ _: 8, md: 12 }}>
          <CardBadge variant={variant} isActive={isActive} />
        </Box>
        <div>
          <Text.Duration variant={variant}>{title}</Text.Duration>
          {regularTitle && (
            <Text.RegularDuration variant={variant}>
              {'\u00A0'}
              {regularTitle}
              {'\u00A0'}
            </Text.RegularDuration>
          )}
        </div>
      </Box>
      <Box>
        <Grid.Row alignItems="flex-end" flexDirection="column" justifyContent="center">
          <Box display="flex" alignItems="center" flexWrap="no-wrap">
            <Text.PriceDecorator isActive={isActive} variant={variant}>
              {currency}
            </Text.PriceDecorator>
            <Text.Price isActive={isActive} variant={variant}>
              {price}
            </Text.Price>
          </Box>
          <Text.PriceDecorator isActive={isActive} variant={variant}>
            {pricePeriod}
          </Text.PriceDecorator>
        </Grid.Row>
      </Box>
    </Box>
    {regularPricePerMonth && isHighlighted && (
      <Text.RegularPrice variant={variant}>{regularPricePerMonth}</Text.RegularPrice>
    )}
    {couponText && <Text.Coupon>{couponText}</Text.Coupon>}

    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display={{ _: 'block', md: 'flex' }}>
        <Text.Description variant={variant}>{billingDescription} &nbsp;</Text.Description>
        <Text.Description variant={variant}>{description}</Text.Description>
      </Box>
    </Box>
  </Box>
);

export default LayoutMobile;
